






import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ButtonMixin from "@/mixins/ButtonMixin";

@Component
export default class AccessTypeButton extends mixins(ButtonMixin) {
  public name = "AccessTypeButton";
}
