import i18n from "@/i18n";

export const ARCHIVE_OPTIONS = [
  {
    text: i18n.t("user.active_users"),
    value: false,
  },
  {
    text: i18n.t("user.archived_users"),
    value: true,
  },
];
